import { createMemo, For } from "solid-js";
import { isPrixEncadresOverride } from "~/utils/helper_program";
import { getRegulation, getSlice, hasRegulation } from "~/utils/helper_slice";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { LotDrawerWithPricesProps } from "~/components/Program/Components/LotDrawer";

export default function Autopromos(props: LotDrawerWithPricesProps) {
  const settings = useDrupalSettingsContext();

  const slice = () =>
    getSlice(props.lot.slice_id.meta.drupal_internal__target_id, props.wrapper);

  const promoDrawerVatInc = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.tva_normale)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerVatIncReduced = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.tva_reduite)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerVatIncMastered = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.prix_maitrises)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerVatIncBrs = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.brs)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerPatrimonial = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.patrimonial)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerLmnpVatInc = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerBareOwnership = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.nue_propriete)
      ?.field_promo_drawer?.value;
  });
  const promoDrawerLmnpVatEx = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.lmnp_vat_ex)
      ?.field_promo_drawer?.value;
  });

  const autopromos = createMemo(() => {
    const autopromosTemp: { key: string; markup?: string }[] = [];

    if (
      props.wrapper.program.field_disp_grid_vat_inc &&
      props.pricesInfos.vat_inc &&
      hasRegulation(slice()!, settings.regulations_tids.tva_normale) &&
      promoDrawerVatInc()
    ) {
      autopromosTemp.push({
        key: "vat-inc",
        markup: promoDrawerVatInc(),
        /* Backup
          <IconAutopromo02 />
          <div>
          <h3>Bénéficiez du prêt à taux zéro</h3>
          <p>
            Jusqu’à 40% de votre résidence principale financés sans intérêt,
            dans le cadre d’une primo-accession.
          </p>
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/le-pret-a-taux-zero.html",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (props.pricesInfos.vat_inc_reduced && promoDrawerVatIncReduced()) {
      autopromosTemp.push({
        key: "vat-inc-reduced",
        markup: promoDrawerVatIncReduced(),
        /* Backup
          <IconAutopromo04 />
          <div>
          <h3>Bénéficiez de la TVA réduite</h3>
          <p>
            TVA à 5,5% ou 7% pour votre projet immobilier en zone ANRU et sous
            conditions de ressources. Cumulable au Prêt à Taux Zéro !
          </p>
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/une-tva-reduite-a-5-5.html",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (
      !isPrixEncadresOverride(props.wrapper) &&
      props.pricesInfos.vat_inc_mastered &&
      promoDrawerVatIncMastered()
    ) {
      autopromosTemp.push({
        key: "vat-inc-mastered",
        markup: promoDrawerVatIncMastered(),
        /* Backup
          <IconAutopromo04 />
          <div>
          <h3>Bénéficiez des prix maîtrisés</h3>
          <p>
            Tous les avantages du neuf avec des conditions de financement
            avantageuses pour les primo-accédants.
          </p>
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-aides-pour-devenir-proprietaire/l-accession-a-prix-maitrise.html",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (props.pricesInfos.vat_inc_brs && promoDrawerVatIncBrs()) {
      autopromosTemp.push({
        key: "vat-inc-brs",
        markup: promoDrawerVatIncBrs(),
        /* Backup
          <IconAutopromo01 />
          <div>
          <h3>Nos services pour vous accompagner</h3>
          <p>
            Financement sur mesure, Parrainage,... Profitez de tous nos
            services.
          </p>
          <a
            href={urlRs("journeys", "/cogedim-pour-vous/la-difference-cogedim/")}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (
      props.wrapper.program.field_disp_grid_patrimonial &&
      props.pricesInfos.vat_inc &&
      hasRegulation(slice()!, settings.regulations_tids.patrimonial) &&
      promoDrawerPatrimonial()
    ) {
      autopromosTemp.push({
        key: "patrimonial",
        markup: promoDrawerPatrimonial(),
        /* Backup
          <IconAutopromo02 />
          <div>
          <h3>Au service de nos clients</h3>
          <p>
            Suivi individuel de A à Z, espace client en ligne,.. Bénéficiez
            d’experts à vos côtés tout au long de votre projet chez Cogedim.
          </p>
          <a
            href={urlRs(
              "journeys",
              "/cogedim-pour-vous/la-difference-cogedim/un-accompagnement-sur-mesure.html",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (
      props.wrapper.program.field_disp_grid_lmnp_vat_inc &&
      props.lot.price_vat_inc &&
      props.lot.price_vat_inc > 0 &&
      hasRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc) &&
      promoDrawerLmnpVatInc()
    ) {
      autopromosTemp.push({
        key: "lmnp-vat-inc",
        markup: promoDrawerLmnpVatInc(),
        /* Backup
          <IconAutopromo03 />
          <div>
          <h3>Investissez en LMNP</h3>
          <p>
            Bénéficiez d’une fiscalité allégée en louant meublé pour
            construire votre patrimoine.
          </p>
          <a
            href={urlRs(
              "journeys",
              "/investir/les-solutions-pour-investir-dans-le-neuf/le-dispositif-lmnp/",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (props.pricesInfos.bare_ownership && promoDrawerBareOwnership()) {
      autopromosTemp.push({
        key: "bare-ownership",
        markup: promoDrawerBareOwnership(),
        /* Backup
          <IconAutopromo03 />
          <div>
          <h3>Investissez en Nue-Propriété</h3>
          <p>
            Un bien à prix réduit, défiscalisé, sans contrainte d’entretien et
            de gestion locative.
          </p>
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-solutions-pour-investir/l%E2%80%99achat-en-nue-propriete.html",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    if (props.pricesInfos.lmnp_vat_ex && promoDrawerLmnpVatEx()) {
      autopromosTemp.push({
        key: "lmnp-vat-ex",
        markup: promoDrawerLmnpVatEx(),
        /* Backup
          <IconAutopromo03 />
          <div>
          <h3>Défiscalisez en statut LMNP géré</h3>
          <p>
            Cumulez favorablement la récupération immédiate de la TVA et la
            défiscalisation de vos revenus locatifs à long terme.
          </p>
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-solutions-pour-investir/le-statut-lmp-lmnp.html",
            )}
            class="btn"
          >
            En savoir plus
          </a>
          </div>
          */
      });
    }

    return autopromosTemp;
  });

  return (
    <>
      <section class="lot-drawer-autopromos" data-test="promos">
        <For each={autopromos()}>
          {(autopromo) => (
            <div
              class="autopromo-block"
              data-test={`promo-${autopromo.key}`}
              innerHTML={autopromo.markup}
            />
          )}
        </For>
      </section>
    </>
  );
}
