import { For, Show } from "solid-js";
import {
  getChildrenOfType,
  type PricesAndReturnRates,
} from "~/utils/helper_lot";
import { formatPrice } from "~/utils/format";

import type { LotDrawerProps } from "~/components/Program/Components/LotDrawer";
import { isDateDisplayPricesPast } from "~/utils/helper_program";
import Segments from "~/components/Program/Components/Drawer/Segments";
import { urlRs } from "~/utils/url";

type DrawerPricesProps = LotDrawerProps & {
  pricesInfos: PricesAndReturnRates;
};

export default function Heading(props: DrawerPricesProps) {
  const childrenCount = () => {
    return (
      getChildrenOfType(props.lot, "parking", props.wrapper.lots).length +
      getChildrenOfType(props.lot, "cellar", props.wrapper.lots).length +
      getChildrenOfType(props.lot, "basement", props.wrapper.lots).length
    );
  };

  return (
    <>
      <div class="address" data-test="address">
        <p>{props.wrapper.program.title}</p>
        <address itemscope itemtype="https://schema.org/PostalAddress">
          <span itemProp="streetAddress">
            {props.wrapper.program.field_street}
            <Show when={props.wrapper.program.field_additional}>
              {" "}
              - {props.wrapper.program.field_additional}
            </Show>
          </span>{" "}
          -{" "}
          <span itemProp="addressLocality">
            {props.wrapper.program.field_city}
          </span>{" "}
          <span itemProp="postalCode">
            {props.wrapper.program.field_postal_code?.substring(0, 2)}
          </span>
        </address>
        <Show when={props.isNotFromProgram}>
          <a
            href={urlRs("programs", props.wrapper.program.path.alias)}
            class="btn"
            data-test="btn-program"
          >
            Voir le programme
          </a>
        </Show>
      </div>

      <div class="prices-container">
        <h2>
          {props.lot.re_core_entities_lot_type.name}{" "}
          <span data-test="number">{props.lot.number}</span>
        </h2>
        <Show
          when={isDateDisplayPricesPast(props.wrapper)}
          fallback={
            <strong class="hidden-prices">
              Prix
              <br />
              nous consulter
            </strong>
          }
        >
          <div class="flex-prices">
            {/* PRICE VAT INC */}
            <Show when={props.pricesInfos.vat_inc}>
              <PriceVatIncDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={props.pricesInfos}
                childrenCount={childrenCount()}
              />
            </Show>
            {/* PRICE VAT INC REDUCED */}
            <Show when={props.pricesInfos.vat_inc_reduced}>
              <PriceVatIncReducedDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={props.pricesInfos}
                childrenCount={childrenCount()}
              />
            </Show>
            {/* PRICE VAT INC BRS */}
            <Show when={props.pricesInfos.vat_inc_brs}>
              <PriceVatIncBRSDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={props.pricesInfos}
                childrenCount={childrenCount()}
              />
            </Show>
            {/* PRICE VAT INC MASTERED */}
            <Show when={props.pricesInfos.vat_inc_mastered}>
              <PriceVatIncMasteredDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={props.pricesInfos}
                childrenCount={childrenCount()}
              />
            </Show>
            {/* PRICE BARE OWNERSHIP */}
            <Show when={props.pricesInfos.bare_ownership}>
              <PriceBareOwnershipDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={props.pricesInfos}
                childrenCount={childrenCount()}
              />
            </Show>
            {/* PRICE LMNP VAT EX */}
            <Show when={props.pricesInfos.lmnp_vat_ex}>
              <PriceLMNPVatExDetails
                wrapper={props.wrapper}
                lot={props.lot}
                pricesInfos={props.pricesInfos}
              />
            </Show>
          </div>
        </Show>

        <Show
          when={
            props.lot.field_is_access ||
            props.lot.field_is_invest ||
            props.lot.field_is_signature
          }
        >
          <Segments lot={props.lot} />
        </Show>
      </div>
    </>
  );
}

function PriceVatIncDetails(
  props: DrawerPricesProps & { childrenCount: number },
) {
  return (
    <>
      <div class="price-group" data-test="price-vat-inc">
        <span class="label">{props.pricesInfos.vat_inc!.price_label}</span>
        <span class="price">{props.pricesInfos.vat_inc!.price}</span>
        <span class="parkings-cellars-basements">
          <Show when={props.childrenCount > 0}>
            dont
            <span class="building" data-test="building">
              Logement {formatPrice(props.lot.price_vat_inc!)}
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "parking", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="parking" data-test="parkings">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "parking",
                  props.wrapper.lots,
                )}
              >
                {(parking) => (
                  <span class="parking" data-test="parking">
                    {parking.typology.name}{" "}
                    <Show
                      when={parking.price_vat_inc && parking.price_vat_inc > 0}
                      fallback="inclus"
                    >
                      {formatPrice(parking.price_vat_inc!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "cellar", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="cellar" data-test="cellars">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "cellar",
                  props.wrapper.lots,
                )}
              >
                {(cellar) => (
                  <span class="cellar" data-test="cellar">
                    {cellar.typology.name}{" "}
                    <Show
                      when={cellar.price_vat_inc && cellar.price_vat_inc > 0}
                      fallback="inclus"
                    >
                      {formatPrice(cellar.price_vat_inc!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "basement", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="basement" data-test="basements">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "basement",
                  props.wrapper.lots,
                )}
              >
                {(basement) => (
                  <span class="basement" data-test="basement">
                    {basement.typology.name}{" "}
                    <Show
                      when={
                        basement.price_vat_inc && basement.price_vat_inc > 0
                      }
                      fallback="incluse"
                    >
                      {formatPrice(basement.price_vat_inc!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
        </span>
      </div>
    </>
  );
}

function PriceVatIncReducedDetails(
  props: DrawerPricesProps & { childrenCount: number },
) {
  return (
    <>
      <div class="price-group" data-test="price-vat-inc-reduced">
        <span class="label">
          {props.pricesInfos.vat_inc_reduced!.price_label}
        </span>
        <span class="price">{props.pricesInfos.vat_inc_reduced!.price}</span>

        <span class="parkings-cellars-basements">
          <Show when={props.childrenCount > 0}>
            dont
            <span class="building" data-test="building">
              Logement {formatPrice(props.lot.price_vat_inc_reduced!)}
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "parking", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="parking" data-test="parkings">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "parking",
                  props.wrapper.lots,
                )}
              >
                {(parking) => (
                  <span class="parking" data-test="parking">
                    {parking.typology.name}{" "}
                    <Show
                      when={
                        parking.price_vat_inc_reduced &&
                        parking.price_vat_inc_reduced > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(parking.price_vat_inc_reduced!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "cellar", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="cellar" data-test="cellars">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "cellar",
                  props.wrapper.lots,
                )}
              >
                {(cellar) => (
                  <span class="cellar" data-test="cellar">
                    {cellar.typology.name}{" "}
                    <Show
                      when={
                        cellar.price_vat_inc_reduced &&
                        cellar.price_vat_inc_reduced > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(cellar.price_vat_inc_reduced!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "basement", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="basement" data-test="basements">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "basement",
                  props.wrapper.lots,
                )}
              >
                {(basement) => (
                  <span class="basement" data-test="basement">
                    {basement.typology.name}{" "}
                    <Show
                      when={
                        basement.price_vat_inc_reduced &&
                        basement.price_vat_inc_reduced > 0
                      }
                      fallback="incluse"
                    >
                      {formatPrice(basement.price_vat_inc_reduced!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
        </span>
      </div>
    </>
  );
}

function PriceVatIncBRSDetails(
  props: DrawerPricesProps & { childrenCount: number },
) {
  return (
    <>
      <div class="price-group" data-test="price-vat-inc-brs">
        <span class="label">{props.pricesInfos.vat_inc_brs!.price_label}</span>
        <span class="price">{props.pricesInfos.vat_inc_brs!.price}</span>
        <span class="parkings-cellars-basements">
          <Show when={props.childrenCount > 0}>
            dont
            <span class="building" data-test="building">
              Logement {formatPrice(props.lot.price_vat_inc_brs!)}
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "parking", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="parking" data-test="parkings">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "parking",
                  props.wrapper.lots,
                )}
              >
                {(parking) => (
                  <span class="parking" data-test="parking">
                    {parking.typology.name}{" "}
                    <Show
                      when={
                        parking.price_vat_inc_brs &&
                        parking.price_vat_inc_brs > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(parking.price_vat_inc_brs!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "cellar", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="cellar" data-test="cellars">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "cellar",
                  props.wrapper.lots,
                )}
              >
                {(cellar) => (
                  <span class="cellar" data-test="cellar">
                    {cellar.typology.name}{" "}
                    <Show
                      when={
                        cellar.price_vat_inc_brs && cellar.price_vat_inc_brs > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(cellar.price_vat_inc_brs!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "basement", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="basement" data-test="basements">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "basement",
                  props.wrapper.lots,
                )}
              >
                {(basement) => (
                  <span class="basement" data-test="basement">
                    {basement.typology.name}{" "}
                    <Show
                      when={
                        basement.price_vat_inc_brs &&
                        basement.price_vat_inc_brs > 0
                      }
                      fallback="incluse"
                    >
                      {formatPrice(basement.price_vat_inc_brs!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
        </span>

        <Show when={props.lot.fees_vat_inc}>
          <span class="fees" data-test="fees">
            Redevance {formatPrice(props.lot.fees_vat_inc!)} / mois
          </span>
        </Show>
      </div>
    </>
  );
}

function PriceVatIncMasteredDetails(
  props: DrawerPricesProps & { childrenCount: number },
) {
  return (
    <>
      <div class="price-group" data-test="price-vat-inc-mastered">
        <span class="label">
          {props.pricesInfos.vat_inc_mastered!.price_label}
        </span>
        <span class="price">{props.pricesInfos.vat_inc_mastered!.price}</span>
        <span class="parkings-cellars-basements">
          <Show when={props.childrenCount > 0}>
            dont
            <span class="building" data-test="building">
              Logement {formatPrice(props.lot.price_vat_inc_mastered!)}
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "parking", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="parking" data-test="parkings">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "parking",
                  props.wrapper.lots,
                )}
              >
                {(parking) => (
                  <span class="parking" data-test="parking">
                    {parking.typology.name}{" "}
                    <Show
                      when={
                        parking.price_vat_inc_mastered &&
                        parking.price_vat_inc_mastered > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(parking.price_vat_inc_mastered!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "cellar", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="cellar" data-test="cellars">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "cellar",
                  props.wrapper.lots,
                )}
              >
                {(cellar) => (
                  <span class="cellar" data-test="cellar">
                    {cellar.typology.name}{" "}
                    <Show
                      when={
                        cellar.price_vat_inc_mastered &&
                        cellar.price_vat_inc_mastered > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(cellar.price_vat_inc_mastered!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "basement", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="basement" data-test="basements">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "basement",
                  props.wrapper.lots,
                )}
              >
                {(basement) => (
                  <span class="basement" data-test="basement">
                    {basement.typology.name}{" "}
                    <Show
                      when={
                        basement.price_vat_inc_mastered &&
                        basement.price_vat_inc_mastered > 0
                      }
                      fallback="incluse"
                    >
                      {formatPrice(basement.price_vat_inc_mastered!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
        </span>
      </div>
    </>
  );
}

function PriceBareOwnershipDetails(
  props: DrawerPricesProps & { childrenCount: number },
) {
  return (
    <>
      <div class="price-group" data-test="price-vat-inc-reduced">
        <span class="label">
          {props.pricesInfos.bare_ownership!.price_label}
        </span>
        <span class="price">{props.pricesInfos.bare_ownership!.price}</span>
        <span class="parkings-cellars-basements">
          <Show when={props.childrenCount > 0}>
            dont
            <span class="building" data-test="building">
              Logement {formatPrice(props.lot.price_vat_inc_reduced!)}
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "parking", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="parking" data-test="parkings">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "parking",
                  props.wrapper.lots,
                )}
              >
                {(parking) => (
                  <span class="parking" data-test="parking">
                    {parking.typology.name}{" "}
                    <Show
                      when={
                        parking.price_vat_inc_reduced &&
                        parking.price_vat_inc_reduced > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(parking.price_vat_inc_reduced!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "cellar", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="cellar" data-test="cellars">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "cellar",
                  props.wrapper.lots,
                )}
              >
                {(cellar) => (
                  <span class="cellar" data-test="cellar">
                    {cellar.typology.name}{" "}
                    <Show
                      when={
                        cellar.price_vat_inc_reduced &&
                        cellar.price_vat_inc_reduced > 0
                      }
                      fallback="inclus"
                    >
                      {formatPrice(cellar.price_vat_inc_reduced!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "basement", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="basement" data-test="basements">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "basement",
                  props.wrapper.lots,
                )}
              >
                {(basement) => (
                  <span class="basement" data-test="basement">
                    {basement.typology.name}{" "}
                    <Show
                      when={
                        basement.price_vat_inc_reduced &&
                        basement.price_vat_inc_reduced > 0
                      }
                      fallback="incluse"
                    >
                      {formatPrice(basement.price_vat_inc_reduced!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
        </span>
      </div>
    </>
  );
}

function PriceLMNPVatExDetails(props: DrawerPricesProps) {
  return (
    <>
      <div class="price-group" data-test="price-vat-ex">
        <span class="label">{props.pricesInfos.lmnp_vat_ex!.price_label}</span>
        <span class="price">
          {formatPrice(
            Number(props.pricesInfos.lmnp_vat_ex!.price_raw) +
              Number(props.lot.price_furniture_vat_ex),
          )}
        </span>
        <span class="parkings-cellars-basements">
          dont
          <span class="detail" data-test="price-vat-ex-building">
            Logement {formatPrice(props.lot.price_vat_ex!)}
          </span>
          <span class="detail" data-test="price-vat-ex-furniture">
            Mobilier {formatPrice(props.lot.price_furniture_vat_ex!)}
          </span>
          <Show
            when={
              getChildrenOfType(props.lot, "parking", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="parking" data-test="parkings">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "parking",
                  props.wrapper.lots,
                )}
              >
                {(parking) => (
                  <span class="parking" data-test="parking">
                    {parking.typology.name}{" "}
                    <Show
                      when={parking.price_vat_ex && parking.price_vat_ex > 0}
                      fallback="inclus"
                    >
                      {formatPrice(parking.price_vat_ex!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "cellar", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="cellar" data-test="cellars">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "cellar",
                  props.wrapper.lots,
                )}
              >
                {(cellar) => (
                  <span class="cellar" data-test="cellar">
                    {cellar.typology.name}{" "}
                    <Show
                      when={cellar.price_vat_ex && cellar.price_vat_ex > 0}
                      fallback="inclus"
                    >
                      {formatPrice(cellar.price_vat_ex!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
          <Show
            when={
              getChildrenOfType(props.lot, "basement", props.wrapper.lots)
                .length > 0
            }
          >
            <span class="basement" data-test="basements">
              <For
                each={getChildrenOfType(
                  props.lot,
                  "basement",
                  props.wrapper.lots,
                )}
              >
                {(basement) => (
                  <span class="basement" data-test="basement">
                    {basement.typology.name}{" "}
                    <Show
                      when={basement.price_vat_ex && basement.price_vat_ex > 0}
                      fallback="incluse"
                    >
                      {formatPrice(basement.price_vat_ex!)}
                    </Show>
                  </span>
                )}
              </For>
            </span>
          </Show>
        </span>
      </div>
    </>
  );
}
