import { createMemo, Show } from "solid-js";

import type { LotDrawerWithPricesProps } from "~/components/Program/Components/LotDrawer";
import { getRegulation, getSlice, hasRegulation } from "~/utils/helper_slice";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

export default function InvestRegulationDetails(
  props: LotDrawerWithPricesProps,
) {
  const settings = useDrupalSettingsContext();
  const slice = () =>
    getSlice(props.lot.slice_id.meta.drupal_internal__target_id, props.wrapper);

  const textDrawerLmnpVatInc = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc)
      ?.field_text_drawer?.value;
  });
  const textDrawerBareOwnership = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.nue_propriete)
      ?.field_text_drawer?.value;
  });
  const textDrawerLmnpVatEx = createMemo(() => {
    return getRegulation(slice()!, settings.regulations_tids.lmnp_vat_ex)
      ?.field_text_drawer?.value;
  });

  return (
    <>
      <Show
        when={
          props.wrapper.program.field_disp_grid_lmnp_vat_inc &&
          props.pricesInfos.vat_inc &&
          hasRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc) &&
          textDrawerLmnpVatInc()
        }
      >
        <section
          class="dispositif-details"
          innerHTML={textDrawerLmnpVatInc()}
          data-test="regulation-text-lmnp-vat-inc"
        />
        {/* Backup
        <h3>Acheter en LMNP</h3>
        <p>
          Investissez dans un logement meublé en statut LMNP et bénéficiez
          d’une fiscalité allégée tout en constituant votre patrimoine.{" "}
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-solutions-pour-investir/le-statut-lmp-lmnp.html",
            )}
          >
            En savoir plus
          </a>
        </p>
        */}
      </Show>
      <Show
        when={props.pricesInfos.bare_ownership && textDrawerBareOwnership()}
      >
        <section
          class="dispositif-details"
          innerHTML={textDrawerBareOwnership()}
          data-test="regulation-text-bare-ownership"
        />
        {/* Backup
        <h3>Acheter en Nue-Propriété</h3>
        <p>
          Achetez la nue-propriété de votre logement dont l'usufruit est
          acquis par un bailleur institutionnel pour une période de 15 à 20
          ans, et profitez d’une décote pouvant aller jusqu’à 40% sur le prix
          de votre futur bien.{" "}
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-solutions-pour-investir/l%E2%80%99achat-en-nue-propriete.html",
            )}
          >
            En savoir plus
          </a>
        </p>
        */}
      </Show>
      <Show when={props.pricesInfos.lmnp_vat_ex && textDrawerLmnpVatEx()}>
        <section
          class="dispositif-details"
          innerHTML={textDrawerLmnpVatEx()}
          data-test="regulation-text-lmnp-vat-ex"
        />
        {/* Backup
        <h3>Acheter en LMNP géré</h3>
        <p>
          Investissez dans une résidence services en statut LMNP et cumulez un
          achat Hors Taxe, grâce à la récupération immédiate de la TVA sur le
          prix de votre logement, et des revenus locatifs garantis par bail
          commercial.{" "}
          <a
            href={urlRs(
              "journeys",
              "/le-neuf-decrypte/des-solutions-pour-investir/le-statut-lmp-lmnp.html",
            )}
          >
            En savoir plus
          </a>
        </p>
        */}
      </Show>
    </>
  );
}
