import { Show } from "solid-js";
import { formatPrice } from "~/utils/format";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { Lot } from "~/types/drupal_jsonapi";

export default function Segments(props: { lot: Lot }) {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <section class="segments" data-test="segments">
        <Show when={props.lot.field_is_access}>
          <div class="segment access" data-test="access">
            <picture>
              <img
                src={settings.marketing_segments_terms.access.field_logo_main}
                alt="Access"
                height="16"
                width="76"
              />
            </picture>
            <Show
              when={
                props.lot.field_access_mp_amount_disp &&
                props.lot.field_access_mp_amount
              }
              fallback={<p />}
            >
              <p>
                <span data-test="catchline">
                  Pour votre première résidence principale
                </span>{" "}
                <span class="amount" data-test="amount">
                  <strong>
                    {formatPrice(props.lot.field_access_mp_amount!)}
                  </strong>{" "}
                  /mois
                </span>
                <span class="group">
                  <span data-test="baseline">
                    avec <strong>Access</strong>
                  </span>
                  <Show when={props.lot.field_access_mp_legals}>
                    <a
                      href={props.lot.field_access_mp_legals}
                      target="_blank"
                      data-test="legals"
                    >
                      Voir les conditions
                    </a>
                  </Show>
                </span>
              </p>
            </Show>
          </div>
        </Show>
        <Show when={props.lot.field_is_invest}>
          <div class="segment avantages" data-test="avantages">
            <picture>
              <img
                src={settings.marketing_segments_terms.invest.field_logo_main}
                alt="Cogedim Avantages"
                height="11"
                width="80"
              />
            </picture>
            <p>
              <span data-test="catchline">
                Les solutions les plus performantes pour investir dans
                l’immobilier
              </span>{" "}
              <span class="baseline" data-test="baseline">
                C’est maintenant qu’il faut investir&nbsp;!
              </span>
            </p>
          </div>
        </Show>
        <Show when={props.lot.field_is_signature}>
          <div class="segment signature" data-test="signature">
            <picture>
              <img
                src={
                  settings.marketing_segments_terms.signature.field_logo_main
                }
                alt="Gogedim Signature"
                height="21"
                width="76"
              />
            </picture>
          </div>
        </Show>
      </section>
    </>
  );
}
