import { Show, createMemo } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";

import type { LotDrawerWithPricesProps } from "~/components/Program/Components/LotDrawer";
import { getSlice, hasRegulation } from "~/utils/helper_slice";
import { encodeStr, openEncodedUrl, urlLmnpSimulator } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { hasNoEscape } from "~/utils/no_escape";

const [, { sendClick }] = useEventsContext();

export default function InvestDetails(props: LotDrawerWithPricesProps) {
  const settings = useDrupalSettingsContext();
  const slice = () =>
    getSlice(props.lot.slice_id.meta.drupal_internal__target_id, props.wrapper);

  return (
    <>
      <section class="invest-details" data-test="body-invest">
        <h3>Investir</h3>
        <Show
          when={
            props.wrapper.program.field_disp_grid_patrimonial &&
            hasRegulation(slice()!, settings.regulations_tids.patrimonial) &&
            props.pricesInfos.vat_inc
          }
        >
          <InvestItemsPatrimonial
            wrapper={props.wrapper}
            lot={props.lot}
            pricesInfos={props.pricesInfos}
          />
        </Show>
        <Show
          when={
            props.wrapper.program.field_disp_grid_lmnp_vat_inc &&
            hasRegulation(slice()!, settings.regulations_tids.lmnp_vat_inc) &&
            props.pricesInfos.vat_inc
          }
        >
          <InvestItemsLMNPVatInc
            wrapper={props.wrapper}
            lot={props.lot}
            pricesInfos={props.pricesInfos}
          />
        </Show>
        <Show
          when={
            props.wrapper.program.field_disp_grid_bare_ownership &&
            hasRegulation(slice()!, settings.regulations_tids.nue_propriete) &&
            props.pricesInfos.bare_ownership
          }
        >
          <InvestItemsBareOwnership
            wrapper={props.wrapper}
            lot={props.lot}
            pricesInfos={props.pricesInfos}
          />
        </Show>
        <Show
          when={
            props.wrapper.program.field_disp_grid_lmnp_vat_ex &&
            hasRegulation(slice()!, settings.regulations_tids.lmnp_vat_ex) &&
            props.pricesInfos.lmnp_vat_ex
          }
        >
          <InvestItemsLMNPVatEx
            wrapper={props.wrapper}
            lot={props.lot}
            pricesInfos={props.pricesInfos}
          />
        </Show>
      </section>
    </>
  );
}

function InvestItemsPatrimonial(props: LotDrawerWithPricesProps) {
  const rate = () => props.pricesInfos.vat_inc?.return_rates.at(0);
  return (
    <>
      <div class="items" data-test="regulation-patrimonial">
        <div class="item" data-test="regulation">
          <span class="label">Dispositif</span>
          <span class="value">Investissement patrimonial</span>
        </div>
        <Show when={rate()}>
          <div class="item" data-test={rate()!.data_test}>
            <span class="label">Rentabilité {rate()!.label}</span>
            <span class="value">{rate()!.rate}*</span>
          </div>
        </Show>
      </div>
    </>
  );
}

function InvestItemsLMNPVatInc(props: LotDrawerWithPricesProps) {
  const settings = useDrupalSettingsContext();
  const encodedUrl = createMemo(() => {
    const url = urlLmnpSimulator({
      wrapper: props.wrapper,
      lot: props.lot,
      settings,
    });
    return encodeStr(url);
  });

  return (
    <>
      <div class="items" data-test="regulation-lmnp-vat-inc">
        <div class="item" data-test="regulation">
          <span class="label">Dispositif</span>
          <span class="value">LMNP</span>
          <Show when={!hasNoEscape()}>
            <button
              type="button"
              class="btn desktop"
              onClick={() => {
                sendClick("simulator-lmnp", "drawer-dwell");
                openEncodedUrl(encodedUrl());
              }}
              data-test="button-simulator"
            >
              Effectuer une simulation
            </button>
          </Show>
        </div>
        <div class="item mobile">
          <Show when={!hasNoEscape()}>
            <button
              type="button"
              class="btn"
              onClick={() => {
                sendClick("simulator-lmnp", "drawer-dwell");
                openEncodedUrl(encodedUrl());
              }}
            >
              Effectuer une simulation
            </button>
          </Show>
        </div>
      </div>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function InvestItemsBareOwnership(props: LotDrawerWithPricesProps) {
  return (
    <>
      <div class="items" data-test="regulation-bare-ownership">
        <div class="item" data-test="regulation">
          <span class="label">Dispositif</span>
          <span class="value">Nue-propriété</span>
        </div>
      </div>
    </>
  );
}

function InvestItemsLMNPVatEx(props: LotDrawerWithPricesProps) {
  const settings = useDrupalSettingsContext();
  const encodedUrl = createMemo(() => {
    const url = urlLmnpSimulator({
      wrapper: props.wrapper,
      lot: props.lot,
      settings,
    });
    return encodeStr(url);
  });

  const rate = () => props.pricesInfos.lmnp_vat_ex?.return_rates.at(0);

  return (
    <>
      <div class="items" data-test="regulation-lmnp-vat-ex">
        <div class="item" data-test="regulation">
          <span class="label">Dispositif</span>
          <span class="value">LMNP géré</span>
          <Show when={!hasNoEscape()}>
            <button
              type="button"
              class="btn desktop"
              onClick={() => {
                sendClick("simulator-lmnp", "drawer-dwell");
                openEncodedUrl(encodedUrl());
              }}
              data-test="button-simulator"
            >
              Effectuer une simulation
            </button>
          </Show>
        </div>
        <Show
          when={
            props.wrapper.program.field_dwelling_type &&
            props.wrapper.program.field_dwelling_type!.length > 0
          }
        >
          <div class="item mobile">
            <Show when={!hasNoEscape()}>
              <button
                type="button"
                class="btn"
                onClick={() => {
                  sendClick("simulator-lmnp", "drawer-dwell");
                  openEncodedUrl(encodedUrl());
                }}
              >
                Effectuer une simulation
              </button>
            </Show>
          </div>
        </Show>
        <Show when={rate()}>
          <div class="item" data-test={rate()!.data_test}>
            <span class="label">Rentabilité {rate()!.label}</span>
            <span class="value">{rate()!.rate}*</span>
          </div>
        </Show>
      </div>
    </>
  );
}
